export default function(instance) {
	return {
		// ? TENANTS (ЖИТЕЛИ)
		// * Получение всех жителей
		load_tenants(params) {
			return instance({
				url: "/users/residents",
				method: "get",
				params: {
					page: params?.page || 1,
					paginate: true,
					page_count: 10,
					...params,
				},
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Получение неподтвержденных жителей
		load_unconfirmed_tenants(params) {
			return instance({
				url: "/rooms",
				method: "get",
				params
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Экспорт жителей в Excel
		export_tenants_list(params) {
			return instance({
				url: `/v2/ksk/users/excel`,
				responseType: "blob",
				contentType:
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				params,
			})
				.then(res => window.URL.createObjectURL(new Blob([res.data])))
				.catch(error => error);
		},

		// * Блокировка пользователя
		block_tenant({ id, data }) {
			return instance({
				url: `/users/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Разблокиврока пользователя
		unblock_tenant({ id, data }) {
			return instance({
				url: `/users/${id}`,
				method: "post",
				data
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Получение информации о жителе по номеру телефона
		load_tenant_info_by_phone(params) {
			return instance({
				url: "/v1/ksk/user/",
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Получение информации о жителе по _id
		load_tenant_info_by_id(id) {
			return instance({
				url: `/users/${id}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Получение информации о rooms по _id
		load_rooms_info_by_id(id) {
			return instance({
				url: `/rooms/${id}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Добавление пользователе
		add_tenant_bpmn({ id, data }) {
			return instance({
				url: `/bpmn/users`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Добавление информации о пользователе
		load_tenant_info({ id, data }) {
			return instance({
				url: `/v2/ksk/users`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Изменение информации о пользователе
		edit_tenant_info({ id, data }) {
			return instance({
				url: `/users/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// Изменение прописки
		edit_registration({ id, data }) {
			return instance({
				url: `/registrations/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// Удаление периода заморозки
		delete_freeze_period(id) {
			return instance({
				url: `/freeze-period/${id}`,
				method: "delete"
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Подтверждение прописки жителя
		confirm_tenant(id, userId) {
			const data = 
			{ 
				_method: "patch",
				approwed: true,
				user_id: userId
			}
			return instance({
				url: `/registrations/${id}/`,
				method: "post",
				data
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Отмена прописки жителя
		decline_tenant(id, userId) {
			const data = 
			{ 
				_method: "patch",
				approwed: false,
				user_id: userId
			}
			return instance({
				url: `/registrations/${id}/`,
				method: "post",
				data
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Выселение пользователя
		delete_tenant(id) {
			return instance({
				url: `/registrations/${id}`,
				method: "delete",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
	};
}
