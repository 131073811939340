export default function(instance) {
	return {
		// * Получение каталога
		load_catalog() {
			return instance({
				url: "/catalog/",
				headers: { "Content-Type": "application/json" },
				method: "get",
			}).then(data => data);
		},

		// * Получение типов работ
		load_work_categories() {
			return instance({
				url: "/v1/news_feed/request/category",
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Получение всех УК
		load_ksk_list() {
			return instance({
				url: "/company/?paginate=false&forget_cache=true",
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Получение подтвержденных УК
		load_ksk_list_confirm() {
			return instance({
				url: "/company/?paginate=false&confirmed=true&confirmed_dispatcher=true&forget_cache=true",
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		load_ksk_list_confirm_admin() {
			return instance({
				url: "/company/?paginate=false&confirmed=true",
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Получение УК 
		load_ksk_list2(page) {
			return instance({
				url: `/v2/administrator/ksk?confirmed=true&page=${page}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// Получение списка подъездов
		load_porch_list(params) {
			return instance({
				url: `/houses/porchs/list`,
				method: "get",
				params
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
	};
	
}
